import { Check as CheckIcon } from '@mui/icons-material'
import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'
import { styled as materialStyled } from '@mui/material/styles'
import dayjs from 'dayjs'
import styled from 'styled-components'

import { COLORS } from '../../../commons/Colors'
import CustomDatePicker from '../../../commons/components/CustomDatePicker'
import LoadingComponent from '../../../commons/components/LoadingComponent'
import SnackBarComponent from '../../../commons/components/SnackBarComponent'
import TextInputComponent from '../../../commons/components/TextInputComponent'
import Tooltip from '../../../commons/components/Tooltip'
import { SnackbarConfigTypes } from '../../../commons/utils/Types'
import GlobalThemeProvider from '../../../style/GlobalTheme'
import { ModalPreview } from './components/ModalPreview'
import { HandleConfigDay } from './NfeEmissionConfigContainer'
import { DateConfig, Informations } from './utils/types'

export type NfeEmissionConfigViewProps = {
    onChangeInformations: (_value: string, _name: string) => void
    getInformationValue: (objectKey: string) => string
    informations: Informations
    handleCustomDateConfig: (params: HandleConfigDay) => void
    dateConfig: DateConfig
    onPressSetNfeEmissionConfig: () => void
    handleCloseSnackbar: () => void
    snackbarConfig: SnackbarConfigTypes
    isLoading: boolean
    onPressCancel: () => void
}

export default function NfeEmissionConfigView({
    onChangeInformations,
    getInformationValue,
    informations,
    handleCustomDateConfig,
    dateConfig,
    onPressSetNfeEmissionConfig,
    handleCloseSnackbar,
    snackbarConfig,
    isLoading,
    onPressCancel,
}: NfeEmissionConfigViewProps) {
    return (
        <GlobalThemeProvider>
            <PageContainer>
                <ConfigContainer>
                    <ConfigLabel formLabel>
                        CONFIGURAR INTERVALO DE DATAS PARA EMISSÃO DAS NFE E O
                        PERÍODO DA PO
                    </ConfigLabel>
                    <DateContainer>
                        <Header>
                            <RowContent>
                                <RowItem>Período</RowItem>
                                <RowItem>Início intervalo</RowItem>
                                <RowItem>Final intervalo</RowItem>
                            </RowContent>
                        </Header>
                        <Row>
                            <RowItem>
                                Período da PO{' '}
                                <Tooltip text="Indica que o período de emissão da NFe está habilitado." />
                            </RowItem>
                            <RowItem>
                                {isLoading ? (
                                    <Skeleton
                                        variant="rectangular"
                                        height={60}
                                        animation="wave"
                                        width={220}
                                    />
                                ) : (
                                    <CustomDatePicker
                                        label="De"
                                        onChange={(value) => {
                                            handleCustomDateConfig({
                                                period: 'start',
                                                value,
                                            })
                                        }}
                                        value={dayjs(
                                            new Date(dateConfig.start),
                                            {
                                                format: 'DD/MM/YYYY',
                                            }
                                        )}
                                    />
                                )}
                            </RowItem>
                            <RowItem>
                                {isLoading ? (
                                    <Skeleton
                                        variant="rectangular"
                                        height={60}
                                        animation="wave"
                                        width={220}
                                    />
                                ) : (
                                    <CustomDatePicker
                                        label="Até"
                                        onChange={(value) => {
                                            handleCustomDateConfig({
                                                period: 'end',
                                                value,
                                            })
                                        }}
                                        value={dayjs(new Date(dateConfig.end), {
                                            format: 'DD/MM/YYYY',
                                        })}
                                    />
                                )}
                            </RowItem>
                        </Row>
                    </DateContainer>
                    <DataContainer>
                        <SectionContainer>
                            <LabelContainer>
                                <ConfigLabel formLabel>
                                    CONFIGURAÇÃO DO CONTEÚDO EXIBIDO NO
                                    APLICATIVO{' '}
                                    <Tooltip text="O texto seguirá os parâmetros de estilo conforme o design que o app apresenta." />
                                </ConfigLabel>
                            </LabelContainer>
                            <InputsContainer>
                                <TextInputComponent
                                    labelText="Dados para preenchimento da nota fiscal"
                                    name="dataToFill"
                                    onChange={onChangeInformations}
                                    getValue={getInformationValue}
                                    isTextArea
                                />

                                <TextInputComponent
                                    labelText="email vinculado ao envio das notas fiscais"
                                    name="emailToSend"
                                    onChange={onChangeInformations}
                                    getValue={getInformationValue}
                                />

                                <TextInputComponent
                                    labelText="email que deve ser colocado como cópia durante envio das notas fiscais"
                                    name="emailToCopy"
                                    onChange={onChangeInformations}
                                    getValue={getInformationValue}
                                />
                                <Divider />
                                <TextInputComponent
                                    labelText="discriminar no corpo da nota fiscal"
                                    name="bodyFields"
                                    onChange={onChangeInformations}
                                    getValue={getInformationValue}
                                    isTextArea
                                />
                                <Divider />
                                <TextInputComponent
                                    labelText="informações adicionais"
                                    name="additionalInfo"
                                    onChange={onChangeInformations}
                                    getValue={getInformationValue}
                                    isTextArea
                                />
                            </InputsContainer>
                        </SectionContainer>
                        <SectionContainer>
                            <LabelContainer>
                                <ConfigLabel formLabel>
                                    PREVIEW DAS INFORMAÇÕES NO APLICATIVO
                                </ConfigLabel>
                            </LabelContainer>
                            {isLoading ? (
                                <Skeleton
                                    variant="rectangular"
                                    height={800}
                                    animation="wave"
                                    width={500}
                                    sx={{ marginLeft: 15 }}
                                />
                            ) : (
                                <ModalPreview
                                    informations={informations}
                                    dateConfig={dateConfig}
                                />
                            )}
                        </SectionContainer>
                    </DataContainer>
                </ConfigContainer>
                <Footer>
                    <WarningText>
                        *Salve as informações após as alterações realizadas. O
                        não salvamento implicará na perda das mesmas.
                    </WarningText>
                    <FormButtonsContainer>
                        <CancelButton
                            variant="outlined"
                            disabled={false}
                            onClick={onPressCancel}
                        >
                            Cancelar
                        </CancelButton>
                        <SaveButton
                            startIcon={
                                isLoading && <LoadingComponent size="small" />
                            }
                            onClick={onPressSetNfeEmissionConfig}
                            disabled={isLoading}
                            endIcon={<CheckIcon />}
                        >
                            Salvar
                        </SaveButton>
                    </FormButtonsContainer>
                </Footer>
                <SnackBarComponent
                    handleClose={handleCloseSnackbar}
                    isVisible={snackbarConfig.isVisible}
                    message={snackbarConfig.message}
                    severity={snackbarConfig.status}
                />
            </PageContainer>
        </GlobalThemeProvider>
    )
}

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${COLORS.secondaryWhite};
`

const ConfigContainer = styled.div`
    display: flex;
    width: -webkit-fill-available;
    flex-direction: column;
    padding: 28px 23px 23px 22px;
    gap: 16px;
    background-color: ${COLORS.primaryWhite};
`

const ConfigLabel = styled.span<{
    formLabel?: boolean
}>`
    display: flex;
    align-items: center;
    flex-grow: 0;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.09px;
    color: ${COLORS.primaryGray};
    ${({ formLabel }) =>
        formLabel &&
        `
        margin: 0px 0px 2px;
    `}
`

const DateContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const DataContainer = styled.div`
    display: flex;
    flex-direction: row;
`

const Header = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    row-gap: 16px;
    border-radius: 3px;
    justify-content: center;
    background-color: ${COLORS.borderGray};
`

const RowContent = styled.div`
    display: flex;
    flex-direction: row;
    width: 70%;
    padding: 10px 0px 10px;
    font-size: 14px;
    font-family: Roboto;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.09px;
    justify-content: space-between;
    color: ${COLORS.primaryGray};
`

const RowItem = styled.div`
    display: flex;
    flex-direction: row;
    width: 24.5%;
    justify-content: center;
    align-items: center;
    padding: 10px;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    border-bottom: solid 1px ${COLORS.borderGray};
    background-color: ${COLORS.primaryWhite};
`

const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px 100px;
    width: 50%;
`

const LabelContainer = styled.div`
    align-self: center;
    margin-bottom: 80px;
`
const Footer = styled.div`
    display: flex;
    width: -webkit-fill-available;
    justify-content: space-between;
    gap: 16px;
    align-items: center;
    padding: 0 10px 30px 20px;
    margin-top: 30px;
`

const SaveButton = materialStyled(Button)({
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.71,
    letterSpacing: 'normal',
    backgroundColor: COLORS.primaryGreen,
    borderColor: COLORS.primaryGreen,
    color: COLORS.primaryWhite,
    fontFamily: 'Roboto',
    '&:hover': {
        backgroundColor: COLORS.secondaryGreen,
        borderColor: COLORS.secondaryGreen,
        boxShadow: 'none',
    },
    '&:disabled': {
        backgroundColor: COLORS.disabledButton,
        borderColor: COLORS.disabledButton,
        color: COLORS.primaryWhite,
        boxShadow: 'none',
    },
})

const CancelButton = materialStyled(Button)({
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.71,
    letterSpacing: 'normal',
    fontFamily: 'Roboto',
})

const WarningText = styled.span`
    color: ${COLORS.primaryGray};
    font-size: 12px;
`

const FormButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 250px;
`

const InputsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 650px;
`

const Divider = styled.hr`
    border: none;
    border-top: 1px dashed ${COLORS.lightBlueGreyTwo};
    margin: 16px 0;
    height: 0;
`
